import React from 'react';
import { connect } from 'react-redux'
import { Redirect } from 'react-router';

import { withStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import Typography from "@material-ui/core/Typography";
import VisibilityIcon from '@material-ui/icons/VisibilityOutlined';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOffOutlined';

import Api from '../../../../../../common/api'
import Local from '../../../../../../common/local'
import StandardSelect from '../../../../shared/components/gn-standard-select';
import StandardInput from '../../../../shared/components/gn-standard-input';

const styles = (theme) => ({
  view: {
    ...theme.internalContainer,
    [theme.breakpoints.up('md')]: {
      padding: theme.internalContainer._themeMdPadding
    },
  },
  button: {
    borderRadius: '10px',
    fontWeight: '600',
    fontSize: '16px',
    height: '55px',
    width: '100%',
  },
  buttonControls: {
    display: "flex",
    justifyContent: "center",
    marginTop: "15px"
  },
  guestText: {
    fontSize: '14px',
    paddingTop: '20px',
    textAlign: 'center'
  },
  noHeaderContainer: {
    padding: '20px 20px 0px 20px',
    maxWidth: '1200px',
    margin: '0 auto 50px auto'
  },
  infoText: {
    fontSize: '12px',
    textAlign: 'center'
  },
  linkText: {
    color: '#222222',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  optInCheckBox: {
    display: 'inline'
  },
  optInContainer: {
    textAlign: 'center',
  },
  optInText: {
    fontSize: '12px',
    textAlign: 'center'
  },
  signInText: {
    fontSize: '14px',
    paddingTop: '10px',
    textAlign: 'center'
  },
});

class StepCreateAccount extends React.Component {

  constructor(props) {

    super(props);

    this.state = {
      countryCode: '',
      password: '',
      preferredLanguage: '1033',
      processing: false,
      showPassword: false,
      username: props.username ? props.username : '',
      validation: {
        countryCode: null,
        password: null,
        preferredLanguage: null,
        username: null,
      }
    };

    this.api = new Api();
    this.generateOnInput = this.generateOnInput.bind(this);
    this.goGuest = this.goGuest.bind(this);
    this.goSignIn = this.goSignIn.bind(this);
    this.goValidateEmail = this.goValidateEmail.bind(this);
    this.onCountryCodeChange = this.onCountryCodeChange.bind(this);
    this.onPreferredLanguageChange = this.onPreferredLanguageChange.bind(this);
    this.onShowPassword = this.onShowPassword.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitPressed = this.onSubmitPressed.bind(this);
  }

  generateOnInput(stateKey) {

    return function (e) {

      this.setState({
        [stateKey]: e.target.value,
        validation: { countryCode: null, password: null, preferredLanguage: null, username: null, }
      });
    }.bind(this);
  }

  goGuest(e) {
    e.preventDefault();
    this.props.goGuest(this.state.username);
  }

  goSignIn(e) {
    e.preventDefault();
    this.props.goSignIn();
  }

  goValidateEmail(username) {
    this.props.goValidateEmail(username);
  }

  onCountryCodeChange(e) {
    this.setState({ 
      countryCode: e.target.value,
      validation: { countryCode: null, password: null, preferredLanguage: null, username: null, }
    });
  }

  onPreferredLanguageChange(e) {
    this.setState({ 
      preferredLanguage: e.target.value,
      validation: { countryCode: null, password: null, preferredLanguage: null, username: null, }
  });
  }

  onShowPassword() {
    this.setState({showPassword: !this.state.showPassword});
  }

  async onSubmitPressed(e) {

    if (e.key === 'Enter') {
      return this.onSubmit();
    }
  }

  async onSubmit() {

    const localeEnabled = this.props.application && this.props.application.registration !== undefined && this.props.application.registration.localeEnabled;

    const {
      countryCode,
      password,
      preferredLanguage,
      username,
    } = this.state;

    const validation = {
      countryCode: localeEnabled ? countryCode.length ? null : 'Please select a country.' : null,
      password: password.length ? null : 'Please enter a password.',
      preferredLanguage: localeEnabled ? preferredLanguage ? null : 'Please select a preferred language.' : null,
      username: username.length ? null : 'Please provide an email address.',
    };

    if (Object.values(validation).some((v) => { return v !== null; })) {
      return this.setState({ validation });
    }

    let payload = {
      username,
      password,
    };

    if (localeEnabled) {
      payload.countryCode = countryCode;
      payload.preferredLanguage = preferredLanguage;
    }

    this.setState({ processing: true });

    const that = this;

    try {
      const result = await this.api.post(`/user${this.props.query.client_id ? `?clientId=${this.props.query.client_id}` : ''}`, payload);

      if (result.code !== 200) {
        this.props.postMessageRaw({
          data: {
            message: result.content.message,
            statusCode: result.code
          },
          event: 'createAccount',
          success: false,
        });

        if (result.code === 412) {
          return this.setState({
            processing: false,
            validation: {
              username: (
                <span>
                Sorry, that email address is already registered. <Link onClick={this.goSignIn} to="/gn">Login instead?</Link>
                </span>
              ),
              password: this.state.validation.password ? this.state.validation.password.slice() : null,
            }
          });
        }
        else if (result.code === 400) {
          this.setState({ processing: false });
          return this.props.onError({
            error: function() {
              if ((result.content.validation &&
                result.content.validation.keys.length &&
                result.content.validation.keys[0] === 'username') ||
                (result.content && result.content.message === 'The email address is not valid.')
              ) {
                validation.username = 'Please enter a valid email address.';
                that.setState({ validation });
                return 'This doesn\'t look like a valid email address. Please check it and try again.';
              }

              if (result.content.message) {
                return result.content.message;
              }
              return undefined;
            }() || 'Please double-check your email address for mistakes and try again.'
          });
        }
        else if (result.code === 424) {
          this.setState({ processing: false });
          this.goValidateEmail(username);
          return;
        }
        else {
          this.setState({ processing: false });
          return this.props.onError({
            error: `Sorry, an unknown error occurred. Please try again shortly or contact customer support for immediate assistance.`
          });
        }
      }

      Local.userLogin(result.content.token, username);
      this.setState({ processing: false });
      this.props.postMessageRaw({event: 'createAccount', success: true, email: username});
      return this.props.goAuthorize();
    }
    catch (e) {
      return this.props.onError({ error: e.toString() });
    }
  }

  redirect(redirect) {
    this.setState({ redirect });
  }

  render() {
    const containerStyle =
      this.props.query.noHeader ?
      this.props.classes.noHeaderContainer :
      this.props.classes.view;

    const localeEnabled = this.props.application && this.props.application.registration !== undefined && this.props.application.registration.localeEnabled;

    const endAdornment = this.state.showPassword ?
      <VisibilityOffIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} /> :
      <VisibilityIcon color="action" style={{'cursor': 'pointer'}} onClick={this.onShowPassword} />;

    const endAdornmentWrapper = (
      <InputAdornment position="end">
        <IconButton
            aria-label="toggle password visibility"
            onClick={this.onShowPassword}
            edge="end"
          >
            {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
          </IconButton>
      </InputAdornment>
    )

    const focusEmail = this.state.username ? false : true;
    const focusPassword = !focusEmail;

    const emailInput = (
      <Grid item xs={12} md={12}>
        <StandardInput
          inputType="email"
          value={this.state.username}
          style={{ marginBottom: '15px' }}
          error={this.state.validation.username}
          attribute="username"
          label="Email Address"
          labelWidth={85}
          focus={focusEmail}
          onChange={this.generateOnInput('username')}
          onKeyUp={this.onSubmitPressed} />
      </Grid>
    );

    const passwordInput = (
      <Grid item xs={12} md={12}>
        <StandardInput
          inputType={this.state.showPassword ? 'text' : 'password'}
          style={{ marginBottom: '15px' }}
          error={this.state.validation.password}
          password={true}
          attribute="password"
          label="Create a password*"
          labelWidth={112}
          focus={focusPassword}
          endAdornment={endAdornment}
          showPasswordRules={true}
          onChange={this.generateOnInput('password')}
          onKeyUp={this.onSubmitPressed} />
      </Grid>
    );

    const buttonStyle = !this.props.query.buttonColor ? null :
    {
      backgroundColor: `#${this.props.query.buttonColor}`
    };

    const bottomControls = (
      <Grid className={this.props.classes.buttonControls} item xs={12}>
        <Button style={buttonStyle} className={this.props.classes.button} color="primary" onClick={this.onSubmit} variant="contained" id="login">
          {this.state.processing ?
            (<CircularProgress size={14} color="inherit" />)
            : 'Create Account'
          }
        </Button>
      </Grid>
    );

    const guestLink = this.props.showGuestLink ? (
      <Grid item xs={12} md={12}>
        <Typography className={this.props.classes.guestText} color="initial" >
          <Link className={this.props.classes.linkText} onClick={this.goGuest} to="/gn">Continue as guest</Link>
        </Typography>
      </Grid>
    ) : null;

    let optInLabel = null;

    if (this.props.optIn) {
      optInLabel = (
          <div
            dangerouslySetInnerHTML={{__html: this.props.optInCopy}}
          />
      );
    }

    const optIn = !this.props.optIn ? null :
      (
        <Grid className={this.props.classes.optInContainer} item xs={12} md={12}>
          <FormControlLabel
              control={<Checkbox className={this.props.classes.optInCheckBox} checked={this.props.optInSelected} color="primary" onChange={this.props.onOptIn} />}
              label={optInLabel}
          />
        </Grid>
      );

    const countrySelect = localeEnabled ? (
      <Grid item xs={12} md={12}>
        <StandardSelect
          attribute="country"
          error={this.state.validation.countryCode}
          label="Country*"
          labelWidth={60}
          menuItems={this.props.locale ? this.props.locale.countries : []}
          style={{ marginBottom: '15px' }}
          value={this.state.countryCode}
          onChange={this.onCountryCodeChange}
        />
      </Grid>
    ) : null;

    const preferredLanguageSelect = localeEnabled ? (
      <Grid item xs={12} md={12}>
        <StandardSelect
          attribute="preferredLanguage"
          error={this.state.validation.preferredLanguage}
          label="Preferred Language*"
          labelWidth={120}
          menuItems={this.props.locale ? this.props.locale.languages: []}
          style={{ marginBottom: '15px' }}
          value={this.state.preferredLanguage}
          onChange={this.onPreferredLanguageChange}
        />
      </Grid>
    ) : null;

    return (
      <Grid container spacing={1} className={containerStyle} >
        {emailInput}
        {passwordInput}
        {countrySelect}
        {preferredLanguageSelect}
        {optIn}
        <Grid item xs={12} md={12}>
          <Typography className={this.props.classes.infoText} color="initial" >
            By creating an account or using our sites or services, I agree to the <a className={this.props.classes.linkText} rel="noopener noreferrer" target="_blank" href="https://www.nbcuniversal.com/terms">Terms of Use</a> and acknowledge that I have read the <a className={this.props.classes.linkText} rel="noopener noreferrer" target="_blank" href="https://www.nbcuniversal.com/privacy?brandA=Golf&intake=Golf">Privacy Policy</a>.
          </Typography>
        </Grid>
        {bottomControls}
        {guestLink}
        <Grid item xs={12} md={12}>
          <Typography className={this.props.classes.signInText} color="initial" >
            Already have an account? <Link className={this.props.classes.linkText} onClick={this.goSignIn} to="/gn">Log in</Link>
          </Typography>
        </Grid>
      </Grid>
    );
  }
}

const mapStateToProps = state => {
  return {
  };
}

const ConnectedStepCreateAccount = connect(mapStateToProps, null)(StepCreateAccount);

export default withStyles(styles)(ConnectedStepCreateAccount);
