import React from 'react';

import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

import { withStyles } from '@material-ui/core/styles';

const styles = (theme) => ({
  selectIcon: {
    cursor: 'pointer',
  }
});

class StandardSelect extends React.Component {
  constructor(props) {

    super(props);

    this.state = {
      opened: false
    };

    this.onClose = this.onClose.bind(this);
    this.onOpen = this.onOpen.bind(this);
    this.onToggle = this.onToggle.bind(this);
  }

  onClose(e) {
    this.setState({ opened: false });
  }

  onOpen(e) {
    this.setState({ opened: true });
  }

  onToggle(e) {
    this.setState({ opened: !this.state.opened });
  }

  render() {

    const {
      attribute,
      disabled = false,
      error = null,
      focus = false,
      pattern = "",
      id = null,
      inputType = null,
      label,
      labelWidth = 70,
      onChange = function () {},
      onKeyUp = function () {},
      menuItems = [],
      password = false,
      startAdornment = null,
      style = {},
      value = '',
    } = this.props;

    const endAdornment = this.state.opened ? 
      <ExpandLessIcon className={this.props.classes.selectIcon} color="action" onClick={this.onToggle} /> : 
      <ExpandMoreIcon className={this.props.classes.selectIcon} color="action" onClick={this.onToggle} />;

    return (
      <FormControl style={style} error={error !== null} variant="outlined">
        <InputLabel htmlFor={attribute}>{label}</InputLabel>
        <Select
          autoFocus={focus}
          color="primary"
          disabled={disabled}
          endAdornment={endAdornment}
          IconComponent={() => null}
          id={id || attribute}
          labelWidth={labelWidth}
          onChange={onChange}
          onClose={this.onClose}
          onOpen={this.onOpen}
          open={this.state.opened}
          pattern={pattern}
          startAdornment={startAdornment}
          style={{ borderRadius: '10px' }}
          value={value}
        >
          {
            menuItems.map((item, index) => {
              return (
                <MenuItem key={`${index}-${item.id}`} value={item.id}>{item.label}</MenuItem>
              );
            })
          }
        </Select>
        {
          error !== null ?
          <FormHelperText id={`${attribute}-error`}>{error}</FormHelperText>
          : null
        }
      </FormControl>
    );
  }
}

export default withStyles(styles)(StandardSelect);
